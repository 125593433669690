module.exports = [{
      plugin: require('/app/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":"692","linkImagesToOriginal":"true","backgroundColor":"transparent","withWebp":"true","wrapperStyle":"margin-bottom:50px; margin-top:50px;"},
    },{
      plugin: require('/app/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3624548-2"},
    },{
      plugin: require('/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
