/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "typeface-montserrat"
import "typeface-merriweather"

import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "prismjs/themes/prism-okaidia.css"
import "./src/assets/sass/prismjs.scss"
import './src/assets/sass/main.scss';